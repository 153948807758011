import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-58f2dc37")
const _hoisted_1 = { class: "mobile-menu__content" }
const _hoisted_2 = { class: "menu" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["href"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "mobileMenu",
    class: _normalizeClass(["mobile-menu", { 'mobile-menu--opened': _ctx.isMobileMenuOpen }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_perfect_scrollbar, { options: { wheelPropagation: false } }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMenu, (m) => {
              return (_openBlock(), _createElementBlock("li", {
                key: m.name,
                class: _normalizeClass(["mobile-menu__item", { 'has-submenu': !!m.child }])
              }, [
                (!m.child)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: m.link
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(m.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(["link-wrapper", { 'link-wrapper--active': _ctx.subMenuStatusHolder[m.name] }])
                    }, [
                      _createElementVNode("div", {
                        onClick: ($event: any) => (_ctx.toggleOpenAndMoveTo(m)),
                        class: "link"
                      }, _toDisplayString(m.title), 9, _hoisted_3),
                      _createVNode(_component_svg_icon, {
                        class: "icon icon--small",
                        src: `icons/arrow-down.svg`
                      }, null, 8, ["src"])
                    ], 2)),
                (m.child)
                  ? (_openBlock(), _createElementBlock("ul", {
                      key: 2,
                      class: _normalizeClass(["sub-menu", { 'sub-menu--open': _ctx.subMenuStatusHolder[m.name] }]),
                      id: `sub-menu-${m.name}`
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(m.child, (c) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: c.name,
                          class: _normalizeClass({ 'has-bullet': c.bullet })
                        }, [
                          _createElementVNode("a", {
                            href: _ctx.fullPath(c)
                          }, [
                            _createElementVNode("span", null, _toDisplayString(c.name), 1)
                          ], 8, _hoisted_5)
                        ], 2))
                      }), 128))
                    ], 10, _hoisted_4))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ])
  ], 2))
}