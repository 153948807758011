
import { Options, mixins } from "vue-class-component";
import { NoScrollMixin } from "@/mixins";
import { MenuInterface } from "@/types";
import { menuModule } from "@/store";
import SvgIcon from "@/components/ui/BaseIcons/SvgIcon.vue";
import config from "@/config";

@Options({
  name: "HeaderMenuMobile",
  components: {
    SvgIcon,
  },
  watch: {
    isMobileMenuOpen(val) {
      if (val) {
        this.preventScrollInElement(this.$refs.mobileMenu);
      }

      this.emitter.emit("toggle-mobile-menu", val);
    },
  },
})
export default class Aside extends mixins(NoScrollMixin) {
  $refs!: {
    mobileMenu: HTMLFormElement;
  };
  private isMobileMenuOpen = false;
  private subMenuStatusHolder: any = {};

  protected get getMenu(): Array<MenuInterface> {
    const originalMenu = [...menuModule.getMenu];

    return originalMenu;
  }

  protected fullPath(i: any): string {
    if (i.external) {
      return i.link;
    }

    return config.base + i.link;
  }

  protected toggleOpenAndMoveTo(o: { [key: string]: string }): void {
    this.toggleOpen(o.name);
    this.moveTo(o);
  }

  private moveTo(o: { [key: string]: string }) {
    const linkNames = ["hpc", "ecorche", "articles", "video"];

    if (!this.subMenuStatusHolder[o.name] && linkNames.includes(o.name)) {
      this.$router.push(o.link);
    }
  }

  private toggleOpen(name: string): void {
    this.subMenuStatusHolder[name] = !this.subMenuStatusHolder[name];

    const panel = document.querySelector(
      `#sub-menu-${name}`
    ) as HTMLFormElement;

    if (panel) {
      const isCollapsed = !this.subMenuStatusHolder[name];

      panel.style.height = (isCollapsed ? 0 : panel.scrollHeight) + "px";
    }
  }

  private clearSubMenuState() {
    this.subMenuStatusHolder = {};

    if (document.querySelectorAll(".sub-menu--open").length) {
      document.querySelectorAll(".sub-menu--open").forEach((el) => {
        el.classList.remove("sub-menu--open");
        el.removeAttribute("style");
      });
    }
  }

  created() {
    menuModule.Load();
  }

  mounted() {
    this.emitter.on("toggle-mobile-menu", (state: boolean) => {
      this.isMobileMenuOpen = state;

      if (!this.isMobileMenuOpen) {
        this.clearSubMenuState();
      }
    });
  }

  beforeDestroy() {
    this.isMobileMenuOpen = false;
    this.clearSubMenuState();
  }
}
